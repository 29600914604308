<script>
import "@plugins/fancybox/jquery.fancybox.css"
import "@plugins/fancybox/jquery.fancybox.js"

export default {
  props: {
    title: {default:"Image Preview"},
    rel: String,
    src: String,
  },
    computed:{
        uploader(){ return this.$root.page.uploader },
        isImage(){ return ["jpeg","jpg","png","gif"].indexOf(this.src.split(".")[1])>-1 }
    },
  mounted: function(){
    if(!this.rel){
      $(this.$el).fancybox();
    }else{
      setTimeout(()=>{
        $("[rel="+this.rel+"]").fancybox();
      }, 300)
    }
  },
};
</script>

<template>
    <a :href="src" :rel="rel" :target="isImage?'_blank':false" class="btn btn-info" v-tooltip="title"><i class="icon-image"></i><i class="ti-image"></i></a>
</template>

